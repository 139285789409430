<template>
  <li class="s-l-cards__item">
    <div class="s-card s-card--product">
      <div class="s-card__header">
        <h3 class="s-card__title">
          {{ product.name }}
          <span class="s-card__subtitle">/&nbsp;{{ product.category }}</span>
        </h3>
        <div class="s-card__info">
          <p class="s-card__price-info">
            <span class="s-u-visualy-hidden"> Odběr stojí </span>
            {{ product.price }}&nbsp;Kč
          </p>
        </div>
      </div>
      <div class="s-card__tags">
        <h4 class="s-u-visualy-hidden">Výsledek</h4>
        <span class="s-card__tag"> Výsledek do {{ product.evaulationTimeMin / 60 }} hod </span>
      </div>
      <p class="s-card__text">
        {{ product.description }}
      </p>
      <button class="s-btn" type="button" @click="pickProduct">Vybrat</button>
    </div>
  </li>
</template>

<script>
import TranlastionMixin from '../../mixins/TranslationMixin';
import { mapActions } from 'vuex';

export default {
  name: 'ProductCardComponent',

  mixins: [TranlastionMixin],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions('orders', ['updateFormData']),

    pickProduct() {
      const formData = {
        productId: this.product.id,
      };
      this.updateFormData(formData);

      this.$router.push({ name: 'ReservationSlot' });
    },
  },
};
</script>

<style scoped>

</style>
