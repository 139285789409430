<template>
  <ul class="s-l-cards" aria-label="PCR testy">
    <ProductCardComponent
      v-for="product in products(formData.locationId)"
      :key="product.id"
      :product="product"
    ></ProductCardComponent>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCardComponent from './ProductCardComponent';

export default {
  name: 'ProductListComponent',

  components: {
    ProductCardComponent,
  },

  computed: {
    ...mapGetters({
      products: 'products/getAllLocationProducts',
    }),
    ...mapGetters({
      formData: 'orders/getFormData',
    }),
  },
};
</script>

<style scoped>

</style>
